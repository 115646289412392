<template>
  <!--begin::Wrapper-->
  <div class="w-100 text-center py-10">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-12">
      <!--begin::Title-->
      <h2 class="fw-bolder text-dark">Mülakat Oluşturuldu!</h2>
      <!--end::Title-->

      <!--begin::Notice-->
      <div class="text-gray-400 fw-bold fs-6">
        Mülakat başarıyla oluşturuldu.
      </div>
      <!--end::Notice-->
    </div>
    <!--end::Heading-->

    <!--begin::Message-->
    <div class="text-gray-700 fw-semibold fs-5 my-4">
      Tebrikler! Mülakatı başarıyla oluşturup adayları davet ettiniz. Adaylar mülakatı bitirdiklerinde sistemimiz üzerinden görebileceksiniz.
    </div>

    <!--begin::Link to see all interviews-->
    <router-link to="/interview/interview-list" class="text-primary fw-bold fs-6">
      Tüm mülakatları görmek için buraya tıklayın.
    </router-link>
    <!--end::Link-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-3",
  components: {
    Field,
    ErrorMessage,
  },
});
</script>
