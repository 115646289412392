
import { defineComponent, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";
// import { QuillEditor } from "vue-quill-editor";
// import "quill/dist/quill.snow.css"; 
import { VueEditor, Quill } from "vue3-editor";

export default defineComponent({
  name: "step-1",
  components: {
    Field,
    ErrorMessage,
    // QuillEditor,
    VueEditor
  },
  setup() {
    const positionName = ref("");
    const positionDesc = ref(""); 
    const featureInput = ref("");
    const features = ref<string[]>([]);

    const addFeature = () => {
      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = ""; 
      }
    };

    const editorOptions = ref({
      theme: "snow", // Quill editor tema ayarı
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ align: [] }],
          ["link", "image"],
          ["clean"],
        ],
      },
    });

    return {
      positionName,
      positionDesc,
      featureInput,
      features,
      addFeature,
      editorOptions,
    };
  },
});
