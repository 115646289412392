<template>
  <!--begin::Wrapper-->
  <div class="w-100">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-15">
      <!--begin::Title-->
      <h2 class="fw-bolder d-flex align-items-center text-dark">
        Pozisyonu Tanımla
        <i
          class="fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="tooltip"
          title="Hangi pozisyon için mülakat oluşturmak istediğinizi tanımlayın."
        ></i>
      </h2>
      <!--end::Title-->

      <!--begin::Notice-->
      <div class="text-gray-400 fw-bold fs-6">
        Mülakat oluşturmak için açık pozisyon oluştur.
      </div>
      <!--end::Notice-->
    </div>
    <!--end::Heading-->

    <!--begin::Input group-->
    <div class="mb-10 fv-row">
      <!--begin::Label-->
      <label class="form-label mb-3">Pozisyon Adı</label>
      <!--end::Label-->

      <!--begin::Input-->
      <Field
        type="text"
        class="form-control form-control-lg form-control-solid"
        name="positionName"
        placeholder=""
        v-model="positionName"
      />
      <ErrorMessage
        name="positionName"
        class="fv-plugins-message-container invalid-feedback"
      ></ErrorMessage>
      <!--end::Input-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="mb-10 fv-row">
      <!--begin::Label-->
      <label class="form-label mb-3">İlan Açıklaması</label>
      <!--end::Label-->

      <vue-editor v-model="positionDesc" class="form-control form-control-solid" style="padding: 0px"></vue-editor>

      <!--begin::Input (HTML Editor)-->
      <!-- <quill-editor
        v-model="positionDesc"
        :options="editorOptions"
        class="form-control form-control-lg form-control-solid"
      /> -->
      <ErrorMessage
        name="positionDesc"
        class="fv-plugins-message-container invalid-feedback"
      ></ErrorMessage>
      <!--end::Input-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="mb-6 fv-row">
      <!--begin::Label-->
      <label class="form-label mb-3">Aranılan Özellikler</label>
      <!--end::Label-->

      <!--begin::Input-->
      <input
        type="text"
        class="form-control form-control-lg form-control-solid"
        v-model="featureInput"
        @keyup.enter="addFeature"
        placeholder="Özellik ekleyin ve Enter'a basın"
        v-on:keydown.enter.prevent
      />
      <!--end::Input-->
    </div>
    <!--end::Input group-->

    <!--begin::Feature List-->
    <div v-if="features.length" class="card-body pt-0">
      <div class="d-flex flex-column text-gray-600">
        <div
          v-for="(feature, index) in features"
          :key="index"
          class="d-flex align-items-center py-2"
        >
          <span class="bullet bg-primary me-3"></span> {{ feature }}
        </div>
      </div>
    </div>
    <!--end::Feature List-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";
// import { QuillEditor } from "vue-quill-editor";
// import "quill/dist/quill.snow.css"; 
import { VueEditor, Quill } from "vue3-editor";

export default defineComponent({
  name: "step-1",
  components: {
    Field,
    ErrorMessage,
    // QuillEditor,
    VueEditor
  },
  setup() {
    const positionName = ref("");
    const positionDesc = ref(""); 
    const featureInput = ref("");
    const features = ref<string[]>([]);

    const addFeature = () => {
      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = ""; 
      }
    };

    const editorOptions = ref({
      theme: "snow", // Quill editor tema ayarı
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ align: [] }],
          ["link", "image"],
          ["clean"],
        ],
      },
    });

    return {
      positionName,
      positionDesc,
      featureInput,
      features,
      addFeature,
      editorOptions,
    };
  },
});
</script>
