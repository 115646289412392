<template>
  <!--begin::Wrapper-->
  <div class="w-100">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-12">
      <!--begin::Title-->
      <h2 class="fw-bolder text-dark">Aday Davet Et</h2>
      <!--end::Title-->

      <!--begin::Notice-->
      <div class="text-gray-400 fw-bold fs-6">
        Mülakata adayları davet et!
      </div>
      <!--end::Notice-->
    </div>
    <!--end::Heading-->

    <div class="fv-row mb-8">        
      <label class="form-label fs-5">Birden Fazla Aday Davet Et</label>    
            <!--begin::Dropzone-->
            <div class="dropzone dz-clickable" id="kt_modal_create_project_settings_logo">
                <!--begin::Message-->
                <div class="dz-message needsclick">
                    <!--begin::Icon-->
                    <i class="ki-duotone ki-file-up fs-3hx text-primary"><span class="path1"></span><span class="path2"></span></i>                    
                    <!--end::Icon-->

                    <!--begin::Info-->
                    <div class="ms-4">
                        <h3 class="dfs-3 fw-bold text-gray-900 mb-1">Aday listesi yükle!</h3>
                        <span class="fw-semibold fs-4 text-muted">Yalnızca .xlsx ve .csv uzantılı dosyalar</span>
                    </div>
                    <!--end::Info-->
                </div>
            </div>
            <!--end::Dropzone-->
        </div>

    <!--begin::YA DA Section-->
    <div class="text-center my-4">
      <span class="fw-bold text-gray-900">YA DA</span>
    </div>

    <!--begin::Aday Ekle-->
    <div class="fv-row mb-10">

      <div class="mb-10">
        <label class="form-label required">Aday Adı Soyadı</label>
        <Field
          name="candidateName"
          class="form-control form-control-lg form-control-solid"
        />
      </div>

      <div class="row">
         <div class="col-md-6"> <div class="mb-10">
        <label class="form-label required">E-posta</label>
        <Field
          name="candidateEmail"
          class="form-control form-control-lg form-control-solid"
        />
      </div></div>
         <div class="col-md-6"> <div class="mb-10">
        <label class="form-label required">Telefon</label>
        <Field
          name="candidatePhone"
          class="form-control form-control-lg form-control-solid"
        />
      </div></div>
      </div>

      <div class="mb-2">
        <label class="form-label required">Cv Yükle</label>
        <input
          type="file"
          name="candidateCv"
          class="form-control form-control-lg form-control-solid"
        />
      </div>
      <div class="text-gray-600 fw-bold fs-8 mb-10">
        Sadece pdf uzantılı dosyalar (maks 2mb)
      </div>

      <div class="mb-10">
        <label class="form-label required">İş Tecrübesi</label>
        <Field
          name="candidateExperience"
          class="form-control form-control-lg form-control-solid"
        />
      </div>

      <div class="row">
         <div class="col-md-6">  <div class="mb-10">
        <label class="form-label required">Bitirdiği Bölüm</label>
        <Field
          name="candidateDepartment"
          class="form-control form-control-lg form-control-solid"
        />
      </div></div>
         <div class="col-md-6"> <div class="mb-10">
        <label class="form-label required">Bitirdiği Meslek</label>
        <Field
          name="candidateProfession"
          class="form-control form-control-lg form-control-solid"
        />
      </div></div>
      </div>

    </div>
    <!--end::Aday Ekle-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-3",
  components: {
    Field,
    ErrorMessage,
  },
});
</script>
