<template>
  <div class="w-100">
    <!-- Başlık -->
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder text-dark">Mülakat Oluştur</h2>
      <div class="text-gray-400 fw-bold fs-6">
        Mülakat oluşturmak için lütfen soruları oluşturunuz.
      </div>
    </div>

    <!-- Mülakat Başlığı -->
    <div class="mb-10 fv-row">
      <label class="form-label mb-3">Mülakat Başlığı</label>
      <Field
        type="text"
        class="form-control form-control-lg form-control-solid"
        name="interviewTitle"
        placeholder="Ör: Teknik Değerlendirme Mülakatı / ilk Mülakat Aşaması"
      />
      <ErrorMessage
        name="interviewTitle"
        class="fv-plugins-message-container invalid-feedback"
      />
    </div>

    <div class="mb-10 fv-row">
      <label class="form-label mb-3">Mülakat Açıklaması</label>
      <Field
      as="textarea"
        type="text"
        class="form-control form-control-lg form-control-solid"
        name="interviewDesc"
         placeholder="Ör: Bu mülakat, yazılım geliştirme pozisyonu için adayların teknik becerilerini ve deneyimlerini değerlendirir."
      />
      <ErrorMessage
        name="interviewDesc"
        class="fv-plugins-message-container invalid-feedback"
      />
    </div>

    <div class="row">
      <!-- Başlangıç Tarihi -->
      <div class="col-md-6">
        <div class="fv-row mb-10">
          <label class="d-flex align-items-center form-label mb-5">{{
            $t("modals.interview.startDate")
          }}</label>
          <div class="position-relative align-items-center">
            <Field
              type="date"
              name="startDate"
              class="form-control form-control-lg form-control-solid"
              :placeholder="$t('modals.interview.enterStartDate')"
              :min="today"
              v-model="startDate"
            />
          </div>
        </div>
      </div>

      <!-- Bitiş Tarihi -->
      <div class="col-md-6 fv-row">
        <label class="d-flex align-items-center form-label mb-5">{{
          $t("modals.interview.endDate")
        }}</label>
        <div class="position-relative align-items-center">
          <Field
            type="date"
            name="endDate"
            class="form-control form-control-lg form-control-solid"
            :placeholder="$t('modals.interview.enterEndDate')"
            :min="startDate"
            v-model="endDate"
          />
        </div>
      </div>
    </div>

    <!-- Mülakat Türü Seç -->
    <div class="mb-0 fv-row">
      <label class="d-flex align-items-center form-label mb-5">
        Mülakat Türü Seç
        <i
          class="fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="tooltip"
          title="Mülakat türünü özelleştirip mülakatlarınızı gerçekleştirebilirsiniz."
        ></i>
      </label>

      <div class="mb-0">
        <!-- Özel Mülakat -->
        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <span class="d-flex align-items-center me-2">
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="media/icons/duotune/finance/fin001.svg" />
                </span>
              </span>
            </span>
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
                >Özel Mülakat</span
              >
              <span class="fs-6 fw-bold text-gray-400"
                >Tamamen özelleştirilmiş sorular ile mülakat oluşturun.</span
              >
            </span>
          </span>
          <span class="form-check form-check-custom form-check-solid">
            <Field
              class="form-check-input"
              type="radio"
              name="accountPlan"
              value="1"
              @change="showCustomQuestionInput = true"
            />
          </span>
        </label>

        <!-- Star Metodolojisi -->
        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <span class="d-flex align-items-center me-2">
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="media/icons/duotune/graphs/gra006.svg" />
                </span>
              </span>
            </span>
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
                >Star Metodolojisi</span
              >
              <span class="fs-6 fw-bold text-gray-400"
                >Star metodolojisi ile adayınızdan kendini tanıtmasını ve
                mesleğe bakış açısını anlatmasını isteyin</span
              >
            </span>
          </span>
          <span class="form-check form-check-custom form-check-solid">
            <Field
              class="form-check-input"
              type="radio"
              name="accountPlan"
              value="2"
              @change="showCustomQuestionInput = false"
            />
          </span>
        </label>
      </div>
    </div>

    <div class="fv-row">
      <!-- Sorular Alanı -->
      <div class="mb-5" v-if="showCustomQuestionInput">
        <label class="form-label">Sorular</label>
        <div class="d-flex align-items-center mb-3">
          <!-- Soru Yazma Inputu -->
          <input
            type="text"
            v-model="newQuestion.text"
                        @keyup.enter="addQuestion"
            class="form-control form-control-lg form-control-solid me-3"
            placeholder="Sorunuzu yazın ve Enter'a basın"
            v-on:keydown.enter.prevent
             style="flex-grow: 1;"
          />

          <!-- Soru Tipi Seçme -->
          <select
            v-model="newQuestion.type"
            class="form-select form-select-lg me-3"
            aria-label="Soru Türü"
            style="width: 150px;"
          >
            <option value="open">Açık Uçlu</option>
            <option value="single">Tek Seçenekli</option>
            <option value="multiple">Çok Seçenekli</option>
          </select>

          <!-- Yeni Soru Ekle Butonu -->
          <button type="button" @click="addQuestion" class="btn btn-primary">
            Ekle
          </button>
        </div>

        <!-- Eğer Tek veya Çok Seçenekli ise, Opsiyon Ekleme Alanı Göster -->
        <div
          v-if="
            newQuestion.type === 'single' || newQuestion.type === 'multiple'
          "
          class="mb-3"
        >
          <input
            type="text"
            v-model="newOptionText"
            @keyup.enter="addOption"
            class="form-control form-control-sm me-3"
            placeholder="Opsiyon eklemek için yazın ve Enter'a basın"
            v-on:keydown.enter.prevent
          />
          <!-- Eklenen Opsiyonları Gösterme -->
          <ul class="list-group mt-2">
            <li
              v-for="(option, index) in newQuestion.options"
              :key="index"
              class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
              "
            >
              {{ option }}
              <button type="button"
                @click="removeOption(index)"
                class="btn btn-sm btn-danger"
              >
                Sil
              </button>
            </li>
          </ul>
        </div>
      </div>

      <!-- Eklenen Soruların Listesi -->
      <div v-if="questions.length > 0 && showCustomQuestionInput" class="mt-5">
        <h3 class="form-label">Eklenen Sorular</h3>
        <ul class="list-group">
          <li
            v-for="(question, index) in questions"
            :key="index"
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div>
              <strong>Soru:</strong> {{ question.text }}<br />
              <strong>Tür:</strong>
              {{
                question.type === "open"
                  ? "Açık Uçlu"
                  : question.type === "single"
                  ? "Tek Seçenekli"
                  : "Çok Seçenekli"
              }}
              <ul
                v-if="question.options && question.options.length > 0"
                class="mt-2"
              >
                <li v-for="(option, i) in question.options" :key="i">
                  {{ option }}
                </li>
              </ul>
            </div>
            <button
              @click="removeQuestion(index)"
              class="btn btn-sm btn-danger"
              type="button"
            >
              Sil
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-2",
  components: {
    Field,
    ErrorMessage,
  },
  setup() {
    const today = new Date().toISOString().split("T")[0];
    const startDate = ref(today);
    const endDate = ref("");
    const showCustomQuestionInput = ref(false);

    // Yeni soru nesnesi
    const newQuestion = ref({
      text: "",
      type: "open", // Varsayılan olarak açık uçlu
      options: [] as string[],
    });

    // Eklenen sorular listesi
    const questions: any = ref([]);

    // Yeni opsiyon metni
    const newOptionText = ref("");

    // Soru ekleme fonksiyonu
    const addQuestion = () => {
      if (newQuestion.value.text) {
        questions.value.push({
          ...newQuestion.value,
        });
        newQuestion.value = { text: "", type: "open", options: [] };
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    // Opsiyon silme fonksiyonu
    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    return {
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      showCustomQuestionInput,
      today,
      startDate,
      endDate,
    };
  },
});
</script>

<style scoped>
.list-group-item {
  border: none;
  padding: 10px 15px;
  background-color: #f8f9fa;
  margin-bottom: 5px;
  border-radius: 4px;
}
</style>
